<template>
  <div>
    <div @click="showAnswer()" class="main-color question">
      <v-icon color="teal">{{ qustionIcon }}</v-icon>
       {{ this.question }}
    </div>

    <p v-if="this.toggle" class="answer">
      <v-icon color="teal">{{ answerIcon }}</v-icon>
      {{ this.answer }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'FAQ',
  data () {
    return {
      toggle: false
    }
  },
  props: [
    'question',
    'answer',
    'index',
  ],
  methods: {
    showAnswer () {
      this.toggle = !this.toggle;
    },
  }
}
</script>
<style lang="scss" scoped>

.question {
  background: #f0f0f0;
  // border: 0.5px teal solid;
  border-radius: 15px;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 1rem;
  min-width: 200px;
  transition: background 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    background:darken(#f0f0f0, 10);
  }
}
.answer {
  padding: 0 1rem;
  animation: openAnswer 0.3s linear forwards;
}

@keyframes openAnswer {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-60px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}
</style>