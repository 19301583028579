<template>
  <v-container class="all-faqs main-color">

    <v-layout column justify-center align-center>
    <SearchFAQs @search="searchFAQs"/>
    </v-layout>

    <div class="faq" v-for="(faq, index) in this.getFAQs" :key="index">
      <FAQ :question="faq.question" :answer="faq.answer" :index="index" />
    </div>

  </v-container>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import SearchFAQs from './Components/SearchFAQs'
import FAQ from './Components/FAQ'

export default {
  name: 'FAQs',

  components: {
    SearchFAQs,
    FAQ
  },

  created () {
    this.fetchFAQs();
  },

  methods: {
    ...mapActions('FAQsPage', ['fetchFAQs', 'fetchFAQsWithSearch']),
    searchFAQs (payload) {
      this.fetchFAQsWithSearch(payload);
    },
    
  },

  computed: {
    ...mapGetters('FAQsPage',['getFAQs']),
  }
}
</script>
<style lang="scss" scoped>
.search {
  width: 400px;
}

.all-faqs {
  min-height: 500px;
}
.faq {
  padding: 0.5rem;
}
</style>