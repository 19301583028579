<template>
  <div>
    <h1 class="main-color">{{$t('FAQs.help')}}</h1>

    <v-form 
    @submit="$emit('search', { searchTerm: searchTerm })"  
    class="p-2 d-flex pa-5"
    lazy-validation 
    v-on:submit.prevent
    >
      <v-text-field class="main-color text-lg pa-0 ma-2" :label="$t('FAQs.search')" v-model="searchTerm"></v-text-field>
      <v-btn type="submit" depressed text :color="this.whiteColor" class="pa-2">
      <v-icon large>{{ searchIcon }}</v-icon>
      </v-btn>
    </v-form>

  </div>  
</template>
<script>
export default {
  name: "SearchFAQs",
  data () {
    return {
      searchTerm: '',
    }
    
  },
  methods: {
    search () {
      this.$emit('filter', { term: this.searchTerm })
    }
  }
}
</script>